export function isServiceWorkerSupported(): boolean {
  if (typeof window !== 'undefined') {
    const host = window.location.host
    const queryParams = new URLSearchParams(window.location.search)
    if (
      (host.includes('pwa') || queryParams.get('serviceWorker') === 'on') &&
      'serviceWorker' in navigator
    ) {
      return true
    }
  }

  return false
}

export async function registerServiceWorker(): Promise<
  ServiceWorkerRegistration | undefined
> {
  if (isServiceWorkerSupported()) {
    const sw = 'entry.worker.js'
    return navigator.serviceWorker.register(sw, {
      scope: '/',
      type: 'classic',
    })
  }
}
