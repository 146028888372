import { isServiceWorkerSupported, registerServiceWorker } from './registerSW'
import { logger } from './services/logger'
import { analytics, EVENT_NAMES } from './services/Analytics/Analytics'

export enum SERVICE_WORKER_STATE {
  NOT_NEEDED,
  INSTALLING,
  INSTALLED,
}

const LAST_SERVICE_WORKER_INSTALLED_KEY = 'LAST_SERVICE_WORKER_INSTALLED_KEY'
let refreshing = false
let serviceWorkerState = SERVICE_WORKER_STATE.NOT_NEEDED

const AVOID_UPDATE_TIME = 1000 * 60 * 60 * 24

function isUpdateInTheAllowedTimeWindow() {
  const lastUpdateTime = localStorage.getItem(LAST_SERVICE_WORKER_INSTALLED_KEY)
  if (!lastUpdateTime) return true

  const lastUpdateDate = new Date(lastUpdateTime)
  const currentTime = new Date()
  const diff = currentTime.getTime() - lastUpdateDate.getTime()
  return diff > AVOID_UPDATE_TIME
}

export function getServiceWorkerState() {
  return serviceWorkerState
}

export const installServiceWorkerIfNeeded = () => {
  if (!isServiceWorkerSupported()) {
    logger.info('service worker not needed')
    return
  }

  registerServiceWorker()
    .then((registration) => {
      if (!registration) return

      /* No need to reload, but still update service worker in the background */
      if (
        !navigator.serviceWorker.controller ||
        !isUpdateInTheAllowedTimeWindow()
      ) {
        if (!navigator.serviceWorker.controller) {
          logger.info('service worker not currently installed, no need to wait')
        } else {
          logger.info(
            'already updated in the last time window, no need to wait'
          )
        }

        navigator.serviceWorker.addEventListener('controllerchange', () => {
          logger.info('service worker installed, no reload needed')
          localStorage.setItem(
            LAST_SERVICE_WORKER_INSTALLED_KEY,
            new Date().toISOString()
          )
        })
        return
      }

      registration.addEventListener('updatefound', () => {
        logger.info('service worker update found')
        serviceWorkerState = SERVICE_WORKER_STATE.INSTALLING
      })

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        logger.info('service worker installed')
        if (!refreshing) {
          analytics.track(EVENT_NAMES.SERVICE_WORKER_INSTALLED, {
            'Full URL': window.location.href,
            Path: window.location.pathname,
          })

          localStorage.setItem(
            LAST_SERVICE_WORKER_INSTALLED_KEY,
            new Date().toISOString()
          )
          serviceWorkerState = SERVICE_WORKER_STATE.INSTALLED
        }
      })
    })
    .catch((e) => {
      logger.error('service worker registration failed', e)
      serviceWorkerState = SERVICE_WORKER_STATE.NOT_NEEDED
    })
}
